import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  cmsPaginate,
  cmsListAll,
  // cmsListAll,
  // cmsCreate,
  // cmsUpdate,
  // cmsFindById,
  // cmsDelete,
} from 'services/cms'
import actions from './actions'

export function* GET_ALL({ payload }) {
  const { modules, suffix } = payload
  const scope = yield select(state => state.cms[modules])
  yield put({
    type: 'cms/SET_STATE',
    payload: { loading: true },
  })
  const success = yield call(cmsListAll, suffix)
  if (success) {
    yield put({
      type: 'cms/SET_STATE',
      payload: {
        [modules]: { ...scope, data: success },
      },
    })
  }
  yield put({
    type: 'cms/SET_STATE',
    payload: { loading: false },
  })
}

export function* GET_PAGINATE({ payload }) {
  const { modules, suffix } = payload
  const scope = yield select(state => state.cms[modules])
  const { listTable } = scope
  yield loading({ modules, scope })
  const success = yield call(cmsPaginate, {
    ...listTable.paginate,
    suffix,
    categoryId: scope?.categoryId || '',
  })
  yield loaded({
    modules,
    scope: {
      ...scope,
      listTable: {
        data: success.data || [],
        paginate: { ...listTable.paginate, ...success.paginate },
      },
    },
  })
}

export function* loading(payload) {
  const { modules, scope } = payload

  yield put({
    type: 'cms/SET_STATE',
    payload: {
      [modules]: {
        ...scope,
        loading: true,
      },
    },
  })
}

export function* loaded(payload) {
  const { modules, scope } = payload
  console.log(scope)
  yield put({
    type: 'cms/SET_STATE',
    payload: {
      [modules]: {
        ...scope,
        loading: false,
      },
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_PAGINATE, GET_PAGINATE), takeEvery(actions.GET_ALL, GET_ALL)])
}
