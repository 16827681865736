import React from 'react'
import { Button, Layout, Modal } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { history } from 'index'
import classNames from 'classnames'
import TopBar from 'components/cleanui/layout/TopBar'
import Breadcrumbs from 'components/cleanui/layout/Breadcrumbs'
import BottomNavBar from 'components/cleanui/layout/BottomNavBar'
import Menu from 'components/cleanui/layout/Menu'
import { ImageModal, modalProps } from 'components/kit/modal/ok'

const mapStateToProps = ({ settings, user }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
  isMobileView: settings.isMobileView,
  isProfileEmpty: user.is_profile_empty,
  namaLengkap: user.nama_lengkap,
})

const MainLayout = ({
  children,
  isContentMaxWidth,
  isAppMaxWidth,
  isGrayBackground,
  isSquaredBorders,
  isCardShadow,
  isBorderless,
  isTopbarFixed,
  isGrayTopbar,
  isMobileView,
  isProfileEmpty,
  namaLengkap,
}) => {
  return (
    <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
      <Layout
        className={classNames({
          cui__layout__contentMaxWidth: isContentMaxWidth,
          cui__layout__appMaxWidth: isAppMaxWidth,
          cui__layout__grayBackground: isGrayBackground,
          cui__layout__squaredBorders: isSquaredBorders,
          cui__layout__cardsShadow: isCardShadow,
          cui__layout__borderless: isBorderless,
        })}
      >
        <Menu />
        {isMobileView && <BottomNavBar />}
        <Layout>
          <Layout.Header
            className={classNames('cui__layout__header', {
              cui__layout__fixedHeader: isTopbarFixed,
              cui__layout__headerGray: isGrayTopbar,
            })}
          >
            <TopBar />
          </Layout.Header>
          <Breadcrumbs />
          <Layout.Content
            style={{
              height: '100%',
              position: 'relative',
              background: [
                'url(/resources/images/bgWave.svg) bottom no-repeat',
                'url(/resources/images/topWave.svg) top no-repeat',
              ],
              backgroundAttachment: ['fixed', 'fixed'],
              backgroundSize: ['100%', '100%'],
            }}
          >
            <div className="cui__utils__content">{children}</div>
          </Layout.Content>
          <Modal
            visible={isProfileEmpty && history.location.pathname !== '/profile'}
            {...modalProps}
            style={{
              color: '#1A233A',
            }}
            onCancel={() => history.push('/profile')}
          >
            <div
              style={{
                background: [
                  'url(/resources/images/wave/newUserModalTop.svg) top no-repeat',
                  'url(/resources/images/wave/newUserModalBottom.svg) bottom no-repeat',
                ],
                backgroundSize: ['100%', '100%'],
                padding: 0,
              }}
            >
              <br />
              <br />
              {ImageModal}
              <div className="font-size-28">
                Selamat Datang <span style={{ color: '#2B7DEB' }}>{namaLengkap} !</span>
              </div>
              <div className="font-size-16 m-4">
                {process.env.REACT_APP_NAME} adalah media belajar yang fokus pada kemampuan menjawab
                soal untuk persiapan ujianmu. Dengan sistem up to date sesuai kebutuhan,{' '}
                {process.env.REACT_APP_NAME} hadir menjadi teman berjuang menuju impianmu. Yuk
                Lengkapi Profil Dulu, Sebelum Menggunakan Aplikasi !
              </div>
              <Button
                type="primary"
                onClick={() => history.push('/profile')}
                className="mb-5"
                size="large"
              >
                Eksplor {process.env.REACT_APP_NAME} Sekarang
              </Button>
            </div>
          </Modal>
        </Layout>
      </Layout>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
