import React from 'react'
import { connect } from 'react-redux'
import MenuLeft from './MenuLeft'
import MenuTop from './MenuTop'

const mapStateToProps = ({ settings }) => ({
  menuLayoutType: settings.menuLayoutType,
  isMobileView: settings.isMobileView,
})

const Menu = ({ isMobileView, menuLayoutType }) => {
  const GetMenu = () => {
    if (!isMobileView) {
      if (menuLayoutType === 'top') {
        return <MenuTop />
      }
      if (menuLayoutType === 'nomenu') {
        return null
      }
      return <MenuLeft />
    }
    return <div />
  }

  return GetMenu()
}

export default connect(mapStateToProps)(Menu)
