import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import classNames from 'classnames'
import store from 'store'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { find } from 'lodash'
import { ReactComponent as WaIcon } from 'assets/wa.svg'
import { history } from 'index'
import { MenuOutlined } from '@ant-design/icons'
import style from './style.module.scss'

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  logo: settings.logo,
  role: user.role,
  waValue: settings.waValue,
})

const MenuLeft = ({
  dispatch,
  menuData = [],
  location: { pathname },

  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  role,
  waValue,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])

  useEffect(() => {
    applySelectedKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
    setOpenedKeys([])
  }

  const onOpenChange = keys => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  const generateMenuItems = () => {
    const generateItem = item => {
      const { key, title, url, icon, disabled, count } = item

      if (item.category) {
        return <Menu.ItemGroup key={Math.random()} title={item.title} />
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled} icon={icon && icon}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {count && <span className="badge badge-success ml-2">{count}</span>}
                <span className={style.title}>{title}</span>
              </a>
            )}
            {!item.target && (
              <Link to={url}>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                <span className={style.title}>{title}</span>
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled} icon={icon && icon}>
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-success ml-2">{count}</span>}
          {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
        </Menu.Item>
      )
    }

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
            </span>
          )
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map(menuItem => {
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
          </span>
        )
        return (
          <Menu.SubMenu
            title={subMenuTitle}
            key={menuItem.key}
            icon={menuItem.icon && menuItem.icon}
          >
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  const menuSettings = isMobileView
    ? {
        width: leftMenuWidth,
      }
    : {
        width: leftMenuWidth,
        collapsible: false,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg',
      }
  function hideMenu(p) {
    return p === 'pembahasan' || p === 'excercise' ? 'd-none' : ''
  }

  // Dipisah seperti ini, karena konten mobile akan ngaco ketika digunakan Layout.Sider
  if (isMobileView)
    return (
      <div
        {...menuSettings}
        className={`${classNames(`${style.menu}`, {
          [style.white]: menuColor === 'white',
          [style.gray]: menuColor === 'gray',
          [style.dark]: menuColor === 'dark',
          [style.unfixed]: isMenuUnfixed,
          [style.shadow]: isMenuShadow,
        })} ${hideMenu(history.location.pathname.split('/')[1])}`}
      >
        <MenuContent
          isMenuCollapsed={false}
          isMobileView={isMobileView}
          leftMenuWidth={leftMenuWidth}
          isMenuUnfixed={isMenuUnfixed}
          onCollapse={onCollapse}
          handleClick={handleClick}
          selectedKeys={selectedKeys}
          openedKeys={openedKeys}
          onOpenChange={onOpenChange}
          generateMenuItems={generateMenuItems}
          waValue={waValue}
        />
      </div>
    )

  return (
    <Layout.Sider
      {...menuSettings}
      className={`${classNames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })} ${hideMenu(history.location.pathname.split('/')[1])}`}
    >
      <MenuContent
        isMenuCollapsed={isMenuCollapsed}
        isMobileView={isMobileView}
        leftMenuWidth={leftMenuWidth}
        isMenuUnfixed={isMenuUnfixed}
        onCollapse={onCollapse}
        handleClick={handleClick}
        selectedKeys={selectedKeys}
        openedKeys={openedKeys}
        onOpenChange={onOpenChange}
        generateMenuItems={generateMenuItems}
        waValue={waValue}
      />
    </Layout.Sider>
  )
}

const MenuContent = ({
  isMenuCollapsed,
  isMobileView,
  leftMenuWidth,
  isMenuUnfixed,
  onCollapse,
  handleClick,
  selectedKeys,
  openedKeys,
  onOpenChange,
  generateMenuItems,
  waValue,
}) => {
  const openBerandaPage = () => {
    handleClick({ key: 'beranda' })
    window.location.href = `${window.location.origin}/#/beranda`
  }

  return (
    <div
      className={style.menuOuter}
      style={{
        width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
        height: isMobileView || isMenuUnfixed ? 'calc(100% - 1px)' : 'calc(100% - 1px)',
      }}
    >
      <div
        className={style.logoContainer}
        onClick={isMobileView ? () => openBerandaPage() : () => onCollapse()}
        aria-hidden="true"
        role="button"
      >
        <div className={`${style.logo} ${isMobileView && 'd-flex align-items-center'}`}>
          <>
            {isMobileView ? (
              //   <img src="/logo.svg" alt="logo" style={{ height: '96px', padding: '32px 0' }} />

              <>
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    color: '#2b7deb',
                    marginLeft: 'auto',
                  }}
                >
                  Siapjadi
                </div>
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '1.5rem',
                    color: 'rgb(254, 202, 29)',
                    marginRight: 'auto',
                  }}
                >
                  ASN
                </div>
              </>
            ) : (
              <img src={isMenuCollapsed ? '/favicon.svg' : '/logo.svg'} alt="logo" height="100%" />
            )}
          </>
          {!isMobileView && !isMenuCollapsed && (
            <MenuOutlined style={{ fontSize: '20px' }} className="ml-3" />
          )}
        </div>
      </div>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Menu
          onClick={handleClick}
          selectedKeys={selectedKeys}
          openKeys={openedKeys}
          onOpenChange={onOpenChange}
          mode="inline"
          className={`${style.navigation} align-middle`}
          inlineIndent="15"
        >
          {generateMenuItems()}
        </Menu>
        {!isMenuCollapsed && (
          <div>
            <div style={{ marginBottom: -48 }} className="text-center pt-4">
              <WaIcon />
            </div>
            <div
              style={{ borderRadius: 10, backgroundColor: '#F0F3F8', paddingTop: 50 }}
              className="text-center m-3"
            >
              <span>Bingung pakai aplikasi ini ?</span>
              <br />
              <span>Tanya kami di Whatsapp</span>
              <a
                className="btn btn-outline-primary my-3"
                size="large"
                href={`https://wa.me/${waValue}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Tanya di Whatsapp
              </a>
            </div>
            <div style={{ height: 64 }} />
          </div>
        )}
      </PerfectScrollbar>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
