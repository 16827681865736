import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/messaging'
import apiClient from 'services/axios'

const firebaseConfig = {
  apiKey: 'AIzaSyCcL84XPcuMmYEQLHnWEbHfX5KvZKmLUB4',
  authDomain: 'moorid-6dea7.firebaseapp.com',
  projectId: 'moorid-6dea7',
  storageBucket: 'moorid-6dea7.appspot.com',
  messagingSenderId: '108203963016',
  appId: '1:108203963016:web:6b116b2bddbc0ee69d253d',
  measurementId: 'G-XH6W9MCKZE',
}

firebase.initializeApp(firebaseConfig)
export const firebaseAuth = firebase.auth()
export const firebaseDatabase = firebase.database()

export const messaging = process.env.REACT_APP_FIREBASE === 'true' ? firebase.messaging() : {}

export async function login(email, password) {
  return firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(() => true)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function register(email, password, name) {
  return firebaseAuth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      if (response.user) {
        const { uid } = response.user
        firebaseDatabase
          .ref('users')
          .child(uid)
          .set({
            role: 'admin',
            name,
          })
      }
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = auth.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        const getUserData = async () => {
          if (user) {
            const userFields = await firebaseDatabase
              .ref('users')
              .child(user.uid)
              .once('value')
              .then(snapshot => {
                return snapshot.val()
              })
            const mergedUser = Object.assign(user, {
              id: user.uid,
              name: userFields.name,
              role: userFields.role,
              avatar: user.photoUrl,
            })
            return mergedUser
          }
          return user
        }
        resolve(getUserData())
      }, reject)
    })
  }
  return getCurrentUser(firebaseAuth)
}

export async function logout() {
  return firebaseAuth.signOut().then(() => true)
}

export const requestFirebaseNotificationPermission = async ({ platform }) => {
  try {
    const res = Notification.requestPermission()
    if (res === 'granted') {
      return messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .then(async firebaseToken => {
          await apiClient.post(`messaging/firebase/token`, {
            token: firebaseToken,
            platform,
          })
          return firebaseToken
        })
    }
  } catch (error) {
    return false
  }
  return false
}

export const onMessageListener = () =>
  new Promise(resolve => {
    messaging.onMessage(payload => {
      resolve(payload)
    })
  })
