import actions from './actions'

const initialState = {
  listTable: {
    data: [],
    paginate: {
      currentPage: 1,
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      search: '',
      sort: 'voucher_name',
    },
  },
  listAll: [],
  id: '',
  data: '',
  forms: {
    item: [
      {
        name: 'voucher_code',
        rules: [{ required: true }],
        normalize: value => {
          let res = value.replaceAll('-', '').replace(new RegExp(`(?=(.{${4}})+$)`, 'g'), '-')
          if (res.charAt(0) === '-') res = res.substring(1)
          return res
        },
        className: 'col-md-8 pb-md-4',
        children: {
          size: 'large',
          component: 'input',
          placeholder: 'masukkan kode voucher',
        },
      },
    ],
    fields: [{ name: ['voucher_code'] }],
  },
  loading: false,
}

export default function voucherReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
