/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { TabBar } from 'antd-mobile'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import store from 'store'
import style from './style.module.scss'

const mapStateToProps = ({ menu }) => ({
  menuData: menu.menuData,
})
const BottomNavBar = ({ menuData = [] }) => {
  const showOnPathname = ['/beranda', '/raporbelajar', '/try-out', '/top-up', '/ebook']

  const location = useLocation()
  const history = useHistory()
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  console.log('🚀 ~ file: index.js:17 ~ BottomNavBar ~ selectedKeys:', selectedKeys)
  //   Beranda | Rapor Belajar | Try Out | Top Up | E-Book
  const tabs = [menuData[0], menuData[4], menuData[3], menuData[1], menuData[5]]
  console.log('🚀 ~ file: index.js:20 ~ BottomNavBar ~ tabs:', tabs)

  if (!showOnPathname.includes(location.pathname)) return <div />

  const cur = tabs.find(tab => tab.url === location.pathname)
  console.log('🚀 ~ file: index.js:26 ~ BottomNavBar ~ cur:', cur)
  if (selectedKeys[0] !== cur.key) setSelectedKeys([cur.key])

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e])

    const curMenuData = tabs.find(tab => tab.key === e)
    const curUrl = curMenuData.url
    history.push(curUrl)

    setSelectedKeys([e])
  }

  return (
    <div className={style.bottomnavbar}>
      <TabBar activeKey={selectedKeys[0]} onChange={e => handleClick(e)}>
        {tabs.map(item => (
          <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
        ))}
      </TabBar>
    </div>
  )
}

export default connect(mapStateToProps)(BottomNavBar)
