import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import {
  reportsSummary,
  reportsMaterial,
  reportsSubMaterial,
  reportsCategory,
} from 'services/reports'
import { bundleFuckingBundle } from 'services/bundle'
import actions from './actions'

export function* GET_SUMMARY() {
  yield loading()

  const success = yield call(reportsSummary)
  if (success) {
    yield put({
      type: 'reports/SET_STATE',
      payload: {
        summary: success,
      },
    })
    yield put({
      type: 'reports/GET_CATEGORY',
    })
    yield firstFetch()
  }
  yield loaded()
}

export function* firstFetch() {
  const { listFuckingBundle } = yield select(state => state.bundle)
  yield loading()
  const success = yield call(bundleFuckingBundle, { ...listFuckingBundle.paginate, perPage: 1000 })
  if (success) {
    const res = success.data.filter(e => e.packet_answer_user_status === 'selesai')
    yield put({
      type: 'bundle/SET_STATE',
      payload: {
        listFuckingBundle: {
          data: res,
          paginate: {
            ...listFuckingBundle.paginate,
            ...success.paginate,
          },
        },
      },
    })
    if (res.length) {
      yield put({
        type: 'reports/GET_MATERIAL_REPORT',
        payload: {
          packetId: res[0]?.packet_id,
        },
      })
      console.log(success.data[0].category_id, 'aaa')
      yield put({
        type: 'dashboard/GET_SCORE_PACKET_STATISTIC',
        payload: {
          categoryId: res[0]?.category_id,
        },
      })
    }
  }
  yield loaded()
}

export function* GET_MATERIAL_REPORT({ payload }) {
  yield loading()
  const success = yield call(reportsMaterial, payload)
  if (success) {
    yield put({
      type: 'reports/SET_STATE',
      payload: {
        materialReport: success,
      },
    })
    const { listFuckingBundle } = yield select(state => state.bundle)
    yield put({
      type: 'reports/GET_SUB_MATERIAL_REPORT',
      payload: {
        ...payload,
        materialId: listFuckingBundle.data.find(e => e.packet_id === payload.packetId).category
          .materials[0].id,
      },
    })
  }
  yield loaded()
}

export function* GET_SUB_MATERIAL_REPORT({ payload }) {
  yield loading()
  const success = yield call(reportsSubMaterial, payload)
  if (success) {
    yield put({
      type: 'reports/SET_STATE',
      payload: {
        subMaterialReport: success,
      },
    })
  }
  yield loaded()
}

export function* GET_CATEGORY() {
  yield loading()
  const success = yield call(reportsCategory)
  console.log(success, 'aa')
  if (success) {
    yield put({
      type: 'reports/SET_STATE',
      payload: {
        category: success,
      },
    })
  }
  yield loaded()
}

export function* loading() {
  yield put({
    type: 'reports/SET_STATE',
    payload: {
      loading: true,
    },
  })
}

export function* loaded() {
  yield put({
    type: 'reports/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SUMMARY, GET_SUMMARY),
    takeEvery(actions.GET_MATERIAL_REPORT, GET_MATERIAL_REPORT),
    takeEvery(actions.GET_SUB_MATERIAL_REPORT, GET_SUB_MATERIAL_REPORT),
    takeEvery(actions.GET_CATEGORY, GET_CATEGORY),
  ])
}
