import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { CalendarIcon, MoneyBagIcon } from 'assets/icons'
import { Drawer } from 'antd'
import { dateTimeFormat, toThousand } from 'helper'
import UserMenu from './UserMenu'
import MenuLeft from '../Menu/MenuLeft'
import style from './style.module.scss'

const mapStateToProps = ({ user, topup, settings }) => ({
  user,
  amount: topup.balance.koin_balance_amount,
  isMobileView: settings.isMobileView,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  leftMenuWidth: settings.leftMenuWidth,
})
const TopBar = ({ dispatch, user, amount, isMobileView, isMobileMenuOpen, leftMenuWidth }) => {
  const toggleMobileMenu = () => {
    // mencegah background scrolling ketika side menu sedang aktif
    const positionStyle = document.getElementsByClassName('cui__utils__content')[0].style.position

    if (positionStyle === '')
      document.getElementsByClassName('cui__utils__content')[0].style.position = 'fixed'
    else document.getElementsByClassName('cui__utils__content')[0].style.position = ''

    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }

  const history = useLocation()

  if (isMobileView)
    return (
      <MobileComponents
        history={history}
        toggleMobileMenu={toggleMobileMenu}
        isMobileMenuOpen={isMobileMenuOpen}
        leftMenuWidth={leftMenuWidth}
      />
    )
  return <DesktopComponents history={history} amount={amount} user={user} />
}

const MobileComponents = ({ history, toggleMobileMenu, isMobileMenuOpen, leftMenuWidth }) => {
  const openBerandaPage = () => {
    window.location.href = `${window.location.origin}/#/beranda`
  }

  return (
    <div className={style.topbar}>
      {history.pathname.split('/')[1] === 'excercise' ||
      history.pathname.split('/')[1] === 'pembahasan' ? (
        <img
          src="/logo.svg"
          alt="logo"
          style={{ maxHeight: '30px', minHeight: '30px', minWidth: '56.67px' }}
        />
      ) : (
        <>
          <div
            role="button"
            onClick={() => openBerandaPage()}
            onKeyPress={() => openBerandaPage()}
            tabIndex={0}
          >
            <img
              src="/favicon.svg"
              alt="logo"
              style={{ maxHeight: '40px', minHeight: '40px', minWidth: '56.67px' }}
            />
          </div>
          <MobileMenu toggleMobileMenu={toggleMobileMenu} />
          <MobileDrawer
            toggleMobileMenu={toggleMobileMenu}
            isMobileMenuOpen={isMobileMenuOpen}
            leftMenuWidth={leftMenuWidth}
          />
          <UserMenu />
        </>
      )}
    </div>
  )
}

const MobileMenu = ({ toggleMobileMenu }) => {
  return (
    <div
      className={style.handler}
      onClick={toggleMobileMenu}
      onFocus={e => {
        e.preventDefault()
      }}
      onKeyPress={toggleMobileMenu}
      role="button"
      tabIndex="0"
    >
      <div className={style.handlerIcon} />
      <b
        style={{
          color: '#2b7deb',
        }}
      >
        MENU
      </b>
    </div>
  )
}

const MobileDrawer = ({ toggleMobileMenu, isMobileMenuOpen, leftMenuWidth }) => {
  return (
    <Drawer
      closable={false}
      visible={isMobileMenuOpen}
      placement="left"
      className={style.mobileMenu}
      onClose={toggleMobileMenu}
      maskClosable
      getContainer={null}
      width={leftMenuWidth}
    >
      <MenuLeft />
    </Drawer>
  )
}

const DesktopComponents = ({ history, amount, user }) => {
  return (
    <div className={style.topbar}>
      {history.pathname.split('/')[1] === 'excercise' ||
      history.pathname.split('/')[1] === 'pembahasan' ? (
        <img src="/logo.svg" height={30} alt="logo" />
      ) : (
        <CoinInfo amount={amount} />
      )}
      <Calendar />

      <UserInfo user={user} />

      <div>
        <UserMenu />
      </div>
    </div>
  )
}

const CoinInfo = ({ amount }) => {
  return (
    <div className="">
      <span
        className="align-middle p-2"
        style={{
          background: 'rgba(71, 188, 188, 0.07)',
          backgroundImage: 'url(/resources/images/wave/buttonBlue.svg)',
          backgroundSize: 'cover',
          borderRadius: '6px',
        }}
      >
        <MoneyBagIcon className="align-middle mr-2" />
        <span style={{ color: '#47BCBC' }} className="align-middle">
          {toThousand(amount || 0)}
        </span>

        <button
          type="button"
          className="btn btn-sm align-middle ml-2"
          style={{ background: '#47BCBC', borderRadius: '4px' }}
        >
          <Link to="/top-up" className="text-white">
            Top Up
          </Link>
        </button>
      </span>
    </div>
  )
}

const Calendar = () => {
  return (
    <div className="ml-4">
      <span
        style={{
          backgroundColor: '#FFFAF2',
          backgroundImage: 'url(/resources/images/wave/buttonOrange.svg)',
          backgroundSize: 'cover',
          borderRadius: '6px',
          color: '#FE9900',
        }}
        className="align-middle p-2"
      >
        <CalendarIcon className="align-middle mr-2" />
        {dateTimeFormat({}, 'dddd, D MMMM YYYY')}
      </span>
    </div>
  )
}

const UserInfo = ({ user }) => {
  return (
    <div className="ml-auto mr-3">
      <p className="m-0 text-right" style={{ whiteSpace: 'pre-wrap', lineHeight: 'normal' }}>
        <b>{user.nama_lengkap || user.username}</b>
        {'\n'}
        {!user.is_aktif && 'Tidak '}Terverifikasi
      </p>
    </div>
  )
}

export default connect(mapStateToProps)(TopBar)
